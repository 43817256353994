/* eslint-disable global-require */
import { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Web3ContextProvider } from "./hooks/web3Context";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import App from "./App";
import store from "./store";

export default class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {selectedChainId: 56};
  }

  changeChainId = (chainID) => {
    this.setState({
      selectedChainId: chainID
    })
  }
  

  render() {
    return (
      <Web3ContextProvider selectedChainId={this.state.selectedChainId}>
        <Provider store={store}>
          <BrowserRouter basename={"/"}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App chainId={this.state.selectedChainId} changeChainId={this.changeChainId}/>
            </MuiPickersUtilsProvider>
          </BrowserRouter>
        </Provider>
      </Web3ContextProvider>
    );
  }
}
