export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/wkich/unicrypt-subgraph";
export const EPOCH_INTERVAL = 9600;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 3;

export const TOKEN_DECIMALS = 9;

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  56: {
    FACTORY_ADDRESS: "0xca143ce32fe78f1f7019d7d551a6402fc5350c73",
    // LIQUIDITY_LOCKER_ADDRESS: "0xc765bddb93b0d1c1a88282ba0fa6b2d00e3e0c83", // Unicrypt
    LIQUIDITY_LOCKER_ADDRESS: "0xB918F47dBb40119a61B8a417d0c8A776E4978F7e",    
  },
  4: {
    FACTORY_ADDRESS: "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f",
    LIQUIDITY_LOCKER_ADDRESS: "0x1AeAf3DeA34B262e014D588b389581a166E35eE7",
    ROUTER_ADDRESS: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    TEST_PAIR: "0xfe427F49b1c8744f5870ab919C62DF8Cc5D58D17",
  },
  2000: {
    FACTORY_ADDRESS: "0xD27D9d61590874Bf9ee2a19b27E265399929C9C3",
    LIQUIDITY_LOCKER_ADDRESS: "0xB918F47dBb40119a61B8a417d0c8A776E4978F7e",
    ROUTER_ADDRESS: "0xa4EE06Ce40cb7e8c04E127c1F7D3dFB7F7039C81",
  },
  43114: {
    FACTORY_ADDRESS: "0x9ad6c38be94206ca50bb0d90783181662f0cfa10",
    LIQUIDITY_LOCKER_ADDRESS: "0xB918F47dBb40119a61B8a417d0c8A776E4978F7e",
  },
  137: {
    FACTORY_ADDRESS: "0x5757371414417b8c6caad45baef941abc7d3ab32",
    LIQUIDITY_LOCKER_ADDRESS: "0xB918F47dBb40119a61B8a417d0c8A776E4978F7e",
  },
  250: {
    FACTORY_ADDRESS: "0x152ee697f2e276fa89e96742e9bb9ab1f2e61be3",
    LIQUIDITY_LOCKER_ADDRESS: "0xB918F47dBb40119a61B8a417d0c8A776E4978F7e",
  },
  1: {
    FACTORY_ADDRESS: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    LIQUIDITY_LOCKER_ADDRESS: "0xB918F47dBb40119a61B8a417d0c8A776E4978F7e",
  },
};

export const chains = {
  56: {
    name: "BSC",
    eth: "BNB",
    scanUrl: "https://bscscan.com/",
    dexLabel: "Pancakeswap V2",
    ethFee: "1"
  },
  4: {
    name: "Rinkby Testnet",
    eth: "ETH",
    scanUrl: "https://rinkeby.etherscan.io/",
    dexLabel: "Uniswap V2",
    ethFee: "0.13"
  },
  2000: {
    name: "DOGE",
    eth: "WDOGE",
    scanUrl: "https://explorer.dogechain.dog/",
    dexLabel: "Dogeswap",
    ethFee: "3200"
  },
  43114: {
    name: "AVAX",
    eth: "AVAX",
    scanUrl: "https://snowtrace.io/",
    dexLabel: "Trader Joe",
    ethFee: "10"
  },
  137: {
    name: "POLYGON",
    eth: "MATIC",
    scanUrl: "https://polygonscan.com/",
    dexLabel: "Quickswap",
    ethFee: "300"
  },
  250: {
    name: "FANTOM",
    eth: "FTM",
    scanUrl: "https://ftmscan.com/",
    dexLabel: "Spookyswap",
    ethFee: "825"
  },
  1: {
    name: "ETHERIUM",
    eth: "ETH",
    scanUrl: "https://etherscan.io/",
    dexLabel: "Uniswap V2",
    ethFee: "0.13"
  }
}
